import React from 'react'
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";


function Contact() {
  
  const navigate = useNavigate();
  const [inputs, setInputs] = useState([]);

  const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({ ...values, [name]: value }));
  }
  const handleSubmit = async (event) => {
      event.preventDefault();
      try {
          await axios.post(`${process.env.REACT_APP_API_BASE }/contact/`, inputs).then(function (response) {
              console.log(response.data);
              window.alert("Thanks! We will Contact You Soon");
              navigate('/');
          });
      }
      catch (err) {
          console.error(err);
      }
  }
  return (
    <div>
      <div className="sigma_map"> 
      <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d858.5037294047801!2d79.03969889495625!3d12.229341299564428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bacc00d5e0c59d1%3A0x118e8e41d8c5dd53!2sGovernament%20Rice%20Gudown%2C%20Girivalam%20Rd%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1670219530715!5m2!1sen!2sin"  allowFullScreen />
      </div>
      <div className="section mt-negative pt-0">
        <div className="container">
          <form className="sigma_box box-lg m-0 mf_form_validate ajax_submit" encType="multipart/form-data">
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <i className="far fa-user" />
                  <input type="text" placeholder="Full Name" className="form-control dark" name="name" onChange={handleChange}/>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <i className="far fa-envelope" />
                  <input type="email" placeholder="Email Address" className="form-control dark" name="mail" onChange={handleChange}/>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <i className="far fa-pencil" />
                  <input type="text" placeholder="Mobile Number" className="form-control dark" name="mobile" onChange={handleChange}/>
                </div>
              </div>
            </div>
            <div className="form-group">
              <textarea name="message" placeholder="Enter Message" cols={45} rows={5} className="form-control dark" defaultValue={""} onChange={handleChange}/>
            </div>
            <div className="text-center">
              <button type="submit" className="sigma_btn-custom" name="button" onClick={handleSubmit}>Submit Now</button>
              <div className="server_response w-100">
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="section section-padding pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="sigma_icon-block text-center light icon-block-7">
                <i className="flaticon-email" />
                <div className="sigma_icon-block-content">
                  <span>Send Email <i className="far fa-arrow-right" /> </span>
                  <h5> Email Address</h5>
                  <p style={{color:'black'}}>arunagirithirukovil@gmail.com</p>
                </div>
                <div className="icon-wrapper">
                  <i className="flaticon-email" />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sigma_icon-block text-center light icon-block-7">
                <i className="flaticon-call" />
                <div className="sigma_icon-block-content">
                  <span>Call Us Now <i className="far fa-arrow-right" /> </span>
                  <h5> Phone Number </h5>
                  <p style={{color:'black'}}> +91 95007 71772</p>
                  <p style={{color:'black'}}> +91 94430 39348</p>
                  <p style={{color:'black'}}> +91 89030 50820</p>
                </div>
                <div className="icon-wrapper">
                  <i className="flaticon-call" />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sigma_icon-block text-center light icon-block-7">
                <i className="flaticon-location" />
                <div className="sigma_icon-block-content">
                  <span>Address<i className="far fa-arrow-right" /> </span>
                  <p style={{color:'black'}}>Arunagirinathar ManiMandapam,</p>
                  <p style={{color:'black'}}>Chengam Road Junction,</p>
                  <p style={{color:'black'}}>Girivala Paadhai,</p>
                  <p style={{color:'black'}}>Tiruvannamalai -606603</p>
                </div>
                <div className="icon-wrapper">
                  <i className="flaticon-location" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact