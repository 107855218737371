import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function AboutUs() {

    const handleDragStart = (e) => e.preventDefault();
      
    const [banner, setbanner] = useState([]);
    useEffect(() => {
        getbanner();
    }, []);
  
    const getbanner = async()=>{
        try{
          await axios.get(`${process.env.REACT_APP_API_BASE }/banner/`).then(function(response) {
            setbanner(response.data);
        });
        }
        catch(err){
          console.error(err);
        }
    }

    let banner_data_to_html = banner.map((banner,key)=>
            <img key={key} src={`.${banner.image_path.substring(1,1000)}`} onDragStart={handleDragStart} role="presentation" alt=''/>,
        );

  return (
    <div>
        <AliceCarousel mouseTracking items={banner_data_to_html} 
            disableDotsControls={true} 
            disableButtonsControls={true} 
            autoPlay={true} 
            infinite={true}
            animationDuration={1000}
            autoPlayInterval={2000}
        />
        <div>
        <div className="container">
            <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
                <h1>About Us</h1>
            </div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a className="btn-link" href="Home">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                </ol>
            </nav>
            </div>
        </div>
        <div className="section">
            <div className="container">
            <div className="row">

                <div className="col-lg-12">
                <div className="row">
                <div className="sigma_subheader-inner">
                <div className="sigma_subheader-text">
                    <p className='custom_3'>"துதிக்கும் அடியவர்க்கு ஒருவர் கெடுக்க இடர்
                        <br></br>&nbsp; நினைக்கின் அவர் குலத்தை முதல் அறக் களையும்
                        <br></br>&nbsp; எனக்கோர் துணையாகும் சொலர்க்கறிய
                        <br></br>&nbsp; திருப்புகழை உரைத்தவரை அடுத்த பகை 
                        <br></br>&nbsp; அறுத்து எறிய உறுக்கி எழு மறத்தை நிலை காணும் "
                        <br></br><span className='custom_4'>&nbsp;&nbsp;- வேல்  விருத்தம் </span></p>
                </div>
                </div>
                </div>
                </div>
            </div>
            </div>
        </div>

        </div>
        <section className="section">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 d-none d-lg-block">
                <div className="sigma_img-box">
                    <div className="row">
                    <div className="col-lg-6">
                        <img src="assets/img/About-13.jpeg" alt="service" />
                        <img className="ms-0" src="assets/img/About-11.jpeg" alt="service" />
                    </div>
                    <div className="col-lg-6 mt-0 mt-lg-5">
                        <img src="assets/img/About-14.jpeg" alt="service" />
                        <img className="ms-0" src="assets/img/About-12.jpeg" alt="service" />
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-lg-6">
                <div className="me-lg-30">
                    <div className="section-title mb-0 text-start">
                    </div>
                    <p className="blockquote bg-transparent" style={{fontSize:'x-large',lineHeight:'normel'}}>" ஐயா அருணகிரி அப்பா உனைப்போல் மெய்யாக ஒரு சொல் விளம்பினர்  யார் ? "<br></br>- தாயுமானவர்</p>
                    <div className="sigma_icon-block icon-block-3">
                    <div className="icon-wrapper">
                        <img src="assets/img/About-15.jpeg" alt="" />
                    </div>
                    <div className="sigma_icon-block-content">
                        <h3> Arulalar Arunagirinathar Thirukovil</h3>
                        <p style={{fontSize:'x-large',lineHeight:'normel'}}>Arulalar Arunagirinathar Thirukovil Construction work is in progress the Kumbabishekam is about to take place soon</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        <div className="section light-bg">
            <div className="container">
            <div className="sigma_timeline">
                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>Saint Arunagirinathar was born in 1387 in temple town of Thiruvannamalai located in Tamilnadu. He was born on a full moon day in the tamil month of anni and his birth star was moolam. His father died soon after his birth and his pious mother named muthamai and sister aadhi instilled in him, their cultural and religious traditions.  Muthamai was an ardent devotee of lord Arunachala and spent much of her time in temple doing errands. When he was seven years old his mother became sick and was bedridden. She knew that her life was soon going to end. She called elder daughter and told her that her brother arunagiri was a divine and god blessed child. She asked her to rear and to look after him well. She also took a vow from her to do so before breathing her last breath. On attating his teens Arunagiri was attracted to the pleasures of the women and spent his youth in pursuing a life of debauchery. His sister always gave whatever she earned to make her brother happy, and he frequently visited the devadasis. It was said that since he was enjoying his life in dissipation, he started to suffer from leprosy and because of it the women who squandered his wealth started to avoid him.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                    <img src="assets/img/a1.jpeg" alt="service" />
                </div>
                </div>
                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>There came a time when his sister had no money to meet his demands for dissipation. Arunagiri said he was going to kill himself because of this. To prevent Arunagiri from committing suicide, his sister uttered a few words that no sister would ever tell her brother. She said that he could enjoy her and fulfill his sexual desire. upon hearing this arunagiri’s heart burst, eyes watered,and nerves tremble. He felt as if he had been set a fire alive. In short Arunagiri was shell shocked. He realised the blunder he had committed so far. After hearing such a word from his sister he thought living in this world was of no sense. He decided to end his life, went to a temple and hit his head against the pillars and steps, begging for forgiveness. He climbed the vallala gopuram and jumped from the top hanting lord murugas name. But lord Muruga in form a sage grasped him in his folded arms and saved him.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                    <img src="assets/img/a2.jpeg" alt="service" />
                </div>
                </div>
                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>Arunagiri utterly surprised asked the sage why he had saved the sinner. The sage replied “arunagiri you have been saved because of the good deeds of your previous birth and this moment is a new birth for you. Arunagiri asked the sage if he had any message for him. The sage said “remain idle, speechless “and disappread.It was only then did arunagiri realize that the sage was none other than lord Muruga.
                        Arunagiri clearly understood what the sage had meant. Soon he went in to meditation in the mandapam situated at the northern side of the vallala gopuram. Soon the news of arunagiri’s enlightment reached through out the town and people flocked to have glimpse of arunagiri. Days started moving. Arunagiri physical form changed drastically. All the sickness vanished and his dark skin changed to golden color. His beard started growing and there was tejos in his face. Soon 12 years rolled on. Lord Muruga thought it was the appropriate time to glorify him and revealed his true form. He broke Arunagiri’s penance and with his spear wrote the pranava mantra OM on his tongue and asked him to sing his praise. Dumstruck Arunagiri did not respond. Quickly lord uttered the word “muthu ” and asked him to sing a song beginning with the word. Arunagiri admiring the lord sang his first thirupugazh “muthai tharu.” This was the beginning of the nector of “thirupugazh” which is undoubtedly a masterpiece in tamil literature.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a3.jpeg" alt="service" />
                </div>
                </div>
                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>Saint Arunagirinathar travelled through out the temple town singing thirupugazh. His fame spread far and near. The local king hearing about Arunagiri invited him to his palace and praised him, much to the envy of his court poet sambanthandan. Sambanthandan feared that the kings close proximity with Arunagirinathar will be threat to his position.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a4.jpeg" alt="service" />
                </div>
                </div>
                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>Sambanthadan approached the king and said that Arunagiri was a cheater and that he was bluffing to have seen lord muruga. He urged the king to immediately arrange for a test between Arunagiri and him. Sambabthandan vowed that he will make goddesses kali appear by his magical powers and similarly Arunagiri should make lord muruga appear before the king.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a5.jpeg" alt="service" />
                </div>
                </div>

                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>But kali did not appear. Next it was Arunagirinathar’s turn. Arunagirinathar sang sang a couple of songs but lord did not appear. Through his divine vision he could see that lord muruga was on his mothers lap and she was not letting him go. Then Arunagirinathar sang the song ‘adalasenarada’ and ‘maiyil virutham.’ Lord Muruga quickly slipped from his mothers arms and descended towards the scene. Suddenly the north easren pillar of the mandapam blasted and lord muruga emerged from the pillar revealing his true form to the king Arunagirinathar, Sambabthandan and to a privileged few who had assemled there. Accepting defeat Sampanthandan fled thiruvannamalai.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a6.jpeg" alt="service" />
                </div>
                </div>

                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>One day lord muruga appeared in Arunagirinathar’s dream and asked him to come to vayalur. Accepting lords invitation Arunagirinathar proceeded to vayalur. There the lord Revealed himself with one face and four shoulders and asked him to sing in praise of his peoock, spear and his sacred feet. Then the poet travellled to viralimalai where he reveals himself along with his concerts valli and deivayani and grants him the powerful “attamasiddi ” ie the power to change ones physical form to any object or being. From there he proceeds to palani where he receives “japamalai” or sacred beads from the lord. Then after visting thirupuarankundran the saints visits thiruchendur where the lord displays his cosmic dance. Then after visiting many abodes he visits swamimalai where he is given pranava upadesam next only to lord shiva and poet agasthiar.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a7.jpeg" alt="service" />
                </div>
                </div>

                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>After all these divine visits the poet returns to thiruvannamalai. On way he could witness many people with ears cut proceeding in the opposite direction. On questioning them they said they were poets and had their ears cut off by one named villiputharar who forcibily invited them for a poetic duel on the condition their ears cut off on losing. Those who refused to contest were teased and ashamed before the public and would have their poetwood stripped. Arunagirinathar wanted to teach Villiputhurar a lesson. He approahed him and agreed for a contest. When Villuputturar sang his songs Arunagirinathar would quickly respond with proper explanation. Arunagirinathar answered to all of his poems politely and without fear. Then Arunagirinathar started to sing the famous kandahar anthathi. Villiputturar explained the verses in detail. But when Arunagirinathar recited the 54th song Villiputturar could not decipher the meaning. He was in fact dumstruck. He the accepted defeat and asked Arunagirinathar to cut his ears. But Arunagirinathar politely refused to do the cruel act and pardoned him. He advised him not to indulge in these mean activities and instead use his vast literary knowledge and write a tamil version bharatham. The poet agreed to do so. People hailed Arunagirinathar as generous Arunagiri. Arunagirinathar’s fame was at its peak and people from all the eight directions praised him.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a8.jpeg" alt="service" />
                </div>
                </div>

                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>One day lord muruga appeared in Arunagirinathar’s dream and asked him to come to vayalur. Accepting lords invitation Arunagirinathar proceeded to vayalur. There the lord Revealed himself with one face and four shoulders and asked him to sing in praise of his peoock, spear and his sacred feet. Then the poet travellled to viralimalai where he reveals himself along with his concerts valli and deivayani and grants him the powerful “attamasiddi ” ie the power to change ones physical form to any object or being. From there he proceeds to palani where he receives “japamalai” or sacred beads from the lord. Then after visting thirupuarankundran the saints visits thiruchendur where the lord displays his cosmic dance. Then after visiting many abodes he visits swamimalai where he is given pranava upadesam next only to lord shiva and poet agasthiar.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a9.jpeg" alt="service" />
                </div>
                </div>

                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>After all these divine visits the poet returns to thiruvannamalai. On way he could witness many people with ears cut proceeding in the opposite direction. On questioning them they said they were poets and had their ears cut off by one named villiputharar who forcibily invited them for a poetic duel on the condition their ears cut off on losing. Those who refused to contest were teased and ashamed before the public and would have their poetwood stripped. Arunagirinathar wanted to teach Villiputhurar a lesson. He approahed him and agreed for a contest. When Villuputturar sang his songs Arunagirinathar would quickly respond with proper explanation. Arunagirinathar answered to all of his poems politely and without fear. Then Arunagirinathar started to sing the famous kandahar anthathi. Villiputturar explained the verses in detail. But when Arunagirinathar recited the 54th song Villiputturar could not decipher the meaning. He was in fact dumstruck. He the accepted defeat and asked Arunagirinathar to cut his ears. But Arunagirinathar politely refused to do the cruel act and pardoned him. He advised him not to indulge in these mean activities and instead use his vast literary knowledge and write a tamil version bharatham. The poet agreed to do so. People hailed Arunagirinathar as generous Arunagiri. Arunagirinathar’s fame was at its peak and people from all the eight directions praised him.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a10.jpeg" alt="service" />
                </div>
                </div>

                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>In the mean Sambandhandan returned from exile, befriended the king once again and was waiting for an opportune time to take revenge on Arunagirinathar. One day king prabuvudadeva fell ill and subsequently lost his vision. Sambanthandan tried to capatalise this situation. He told the king that parijatha flowers grown in devalogam was a remedy to cure the kings blindness and that Arunagirinathar alone was capable of bringing this flower using his attama siddhi power. When the king requested Arunagirinathar to bring parijatha flower and cure his blindless Arunagirinathar readily obliged. Arunagirinathar shed his physical body in the temple tower and entered into a parrots body. As a parrot he flew to devaloga and brought the parijatha flower to thiruvannamalai. When Arunagirinathar tried to reenter his physical body he was visibly shocked to learn that his body has been burnt by Sambanthandan. Arunagirinathar in the form a parrot sat on the temple tower and spreaded the parijatha fragrance all round the town. The king regains his eyesight and senses that Arunaagirinathar must have returned and searches for him. The people and the king spot him on the temple tower.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a11.jpeg" alt="service" />
                </div>
                </div>

                <div className="row g-0 justify-content-end justify-content-md-around align-items-start sigma_timeline-nodes">
                <div className="col-12 col-md-5 order-3 order-md-1 sigma_timeline-content">
                    <p>In the mean Sambandhandan returned from exile, befriended the king once again and was waiting for an opportune time to take revenge on Arunagirinathar. One day king prabuvudadeva fell ill and subsequently lost his vision. Sambanthandan tried to capatalise this situation. He told the king that parijatha flowers grown in devalogam was a remedy to cure the kings blindness and that Arunagirinathar alone was capable of bringing this flower using his attama siddhi power. When the king requested Arunagirinathar to bring parijatha flower and cure his blindless Arunagirinathar readily obliged. Arunagirinathar shed his physical body in the temple tower and entered into a parrots body. As a parrot he flew to devaloga and brought the parijatha flower to thiruvannamalai. When Arunagirinathar tried to reenter his physical body he was visibly shocked to learn that his body has been burnt by Sambanthandan. Arunagirinathar in the form a parrot sat on the temple tower and spreaded the parijatha fragrance all round the town. The king regains his eyesight and senses that Arunaagirinathar must have returned and searches for him. The people and the king spot him on the temple tower.</p>
                </div>
                <div className="col-2 col-sm-1 px-md-3 order-2 sigma_timeline-image text-md-center">
                    <i className="far fa-circle" />
                </div>
                <div className="col-12 col-md-5 order-1 order-md-3 pb-3 sigma_timeline-date">
                <img src="assets/img/a12.jpeg" alt="service" />
                </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default AboutUs