import {BrowserRouter as Router,Route,Routes} from 'react-router-dom'
import AboutUs from './components/AboutUs';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import Gallery from './components/Gallery';
import Contact from "./components/Contact";
import Blogs from "./components/Blogs";
import Books from "./components/Books";
import Videos from "./components/Videos";
import BlogDetails from './components/BlogDetails';
import ConstructionGallery from './components/ConstructionGallery';

function App() {
  return (
    <Router>
    <Header></Header>
    <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='/Home' element={<Home/>}/>
      <Route exact path='/About-Us' element={<AboutUs/>}/>
      <Route exact path='/Gallery' element={<Gallery/>}/>
      <Route exact path='/Construction-Work' element={<ConstructionGallery/>}/>
      <Route exact path='/Blogs' element={<Blogs/>}/>
      <Route exact path=':id' element={<BlogDetails/>}/>
      <Route exact path='/Videos' element={<Videos/>}/>
      <Route exact path='/Books' element={<Books/>}/>
      <Route exact path='/Contact-Us' element={<Contact/>}/>
    </Routes>
    <Footer></Footer>
    </Router>
  );
}

export default App;
