import React,{ useState }  from 'react';

function Header() {
    const [isActive, setActive] = useState("false");

    const ToggleClass = () => {
        setActive(!isActive);
      };
  return (
    <div id="google_translate_element">
        <aside className={isActive ? "sigma_aside sigma_aside-left" : "sigma_aside sigma_aside-left open"}>
            <ul>
            <li className="menu-item"><a href="Home">Home</a></li>
            <li className="menu-item"><a href="About-Us">About Us</a></li>
            <li className="menu-item"><a href="Blogs">Blog</a></li>
            <li className="menu-item"><a href="Gallery">Gallery</a></li>
            <li className="menu-item"><a href="Construction-Work">Construction Work</a></li>
            <li className="menu-item"><a href="Videos">Videos</a></li>
            <li className="menu-item"><a href="Books">Books</a></li>
            <li className="menu-item"><a href="Contact-Us">Contact Us</a></li>
            </ul>
        </aside>
        <div className="sigma_aside-overlay aside-trigger-left" onClick={ToggleClass}/>
        <header className="sigma_header header-2 can-sticky">
            <div className="sigma_header-middle">
            <nav className="navbar">
                <div className="sigma_header-controls style-2" onClick={ToggleClass}>
                <ul className="sigma_header-controls-inner">
                    <li className="aside-toggler style-2 aside-trigger-right desktop-toggler">
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    </li>
                    <li className="aside-toggler style-2 aside-trigger-left">
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    </li>
                </ul>
                </div>
                <ul className="navbar-nav">
                <li className="menu-item"><a href="Home">Home</a></li>
                <li className="menu-item"><a href="About-Us">About Us</a></li>
                <li className="menu-item"><a href="Blogs">Blogs</a></li>
                <li className="menu-item"><a href="Gallery">Gallery</a></li>
                <li className="menu-item"><a href="Construction-Work">Construction Work</a></li>
                <li className="menu-item"><a href="Videos">Videos</a></li>
                <li className="menu-item"><a href="Books">Books</a></li>
                <li className="menu-item"><a href="Contact-Us">Contact Us</a></li>
                </ul>
                <div className="sigma_header-controls sigma_header-button">
                <div className="sigma_header-contact-inner">
                    <span>Contact Us</span>
                    <h6>95007 71772</h6>
                </div>
                <a className="sigma_btn-custom" href="Contact-US">Contact Now</a>
                </div>
            </nav>
            </div>
        </header>
    </div>
  )
}

export default Header