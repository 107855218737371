import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import { Image } from 'react-bootstrap';
import { Link, useParams} from 'react-router-dom';

function BlogDetails() {

  let params = useParams();

  const [blog, setblog] = useState([]);
  useEffect(() => {
      getblog();
// eslint-disable-next-line
  },[]);
  const getblog = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/blog/${params.id}`).then(function(response) {
          setblog(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
  }

  return (
      <div className="section sigma_post-single">
        <div className="container">
          <div className="row">
            <div className="col-lg-8" style={{width: '100%'}}>
              <div className="post-detail-wrapper">
                <div className="entry-content">
                  <div className="sigma_post-meta">
                    <div className="sigma_post-categories">
                      <Link href="blog-details.html" className="sigma_post-category">{blog.topic}</Link>
                    </div>
                  </div>
                  <h4 className="entry-title">{blog.titttle}</h4>
                  <p dangerouslySetInnerHTML={{__html: blog.description_1}}></p>
                  <p>{blog.description_2}</p>
                  <Image src={`.${blog.image_path?.substring(1,1000)}`} alt="Awesome_Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default BlogDetails