import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import { Image } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function Blogs() {

  const handleDragStart = (e) => e.preventDefault();
      
  const [banner, setbanner] = useState([]);
  useEffect(() => {
      getbanner();
  }, []);

  const getbanner = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/banner/`).then(function(response) {
          setbanner(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
  }

  let banner_data_to_html = banner.map((banner,key)=>
          <img key={key} src={`.${banner.image_path.substring(1,1000)}`} onDragStart={handleDragStart} role="presentation" alt=''/>,
      );

  const [blog, setblog] = useState([]);
  useEffect(() => {
      getblog();
  }, []);

  const getblog = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/blog/`).then(function(response) {
          setblog(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
  }

  let  data_to_html = blog.map((blog,key)=>
        <div key={key} className="col-md-6">
                  <article className="sigma_post">
                    <div className="sigma_post-thumb">
                       <Link to={`/${blog.id}`} >
                          <Image src={`.${blog.image_path.substring(1,1000)}`} alt="Awesome_Image" />
                      </Link>
                    </div>
                    <div className="sigma_post-body">
                      <div className="sigma_post-meta">
                        <div className="me-3">
                          <i className="fas fa-om" />
                          <Link to={`/${blog.id}`}className="sigma_post-category">{blog.topic}</Link>
                        </div>
                        <Link to={`/${blog.id}`}className="sigma_post-date"> <i className="far fa-calendar" />{blog.created_at}</Link>
                      </div>
                      <h5>  <Link to={`/${blog.id}`} >{blog.tittle}</Link></h5>
                      <div className="sigma_post-single-author">
                        <div className="sigma_post-single-author-content">
                          By <p>{blog.created_by}</p>
                        </div>
                      </div>
                    </div>
                  </article>
        </div>
  );

  return (
    <div>
        <AliceCarousel mouseTracking items={banner_data_to_html} 
            disableDotsControls={true} 
            disableButtonsControls={true} 
            autoPlay={true} 
            infinite={true}
            animationDuration={1000}
            autoPlayInterval={2000}
        />
      <div className="container">
            <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
                <h1>Blogs</h1>
            </div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a className="btn-link" href="Home">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Blogs</li>
                </ol>
            </nav>
            </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row">

            <div className="col-lg-5">
              <div className="row">
              <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                  <p className='custom'>"அறிவு ஒன்று அற நின்று, அறிவார் அறிவில் 
                    <br></br>பிறிவு ஒன்று அற நின்ற, பிரான் அலையோ? 
                    <br></br>செறிவு ஒன்று அற வந்து, இருளே சிதைய 
                    <br></br>வெறி வென்றவரோடு உறும் வேலவனே"
                    <br></br><span className='custom_1'>-கந்தர் அனுபூதி </span></p>
              </div>
              </div>
              </div>
            </div>

            <div className="col-lg-2">
            <img src="assets/img/e1.jpg" alt="author" />
            </div>

            <div className="col-lg-5">
              <div className="row">
              <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                  <p className='custom'>"எம் தாயும் எனக்கு அருள் தந்தையும் நீ 
                     <br></br>சிந்தாகுலம் ஆனவை தீர்த்து எனையாள் 
                     <br></br>கந்தா, கதிர் வேலவனே, உமையாள் 
                     <br></br>மைந்தா, குமரா, மறை நாயகனே"
                     <br></br><span className='custom_1'>-கந்தர் அனுபூதி </span></p>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="section">
          <div className="container">
            <div className="row">
              <div className="col-lg-8" style={{width: '100%'}}>
                <div className="row">
                  {data_to_html}
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Blogs