import React from 'react'

function Footer() {
  return (
    <footer className="sigma_footer footer-2">
        <div className="sigma_footer-middle">
            <div className="container">
            <div className="row">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">About Us</h5>
                <p className="mb-4" style={{color:'#285430',fontSize:'x-large'}}>" முயற்சி செய்வது உனது செயல் <br></br>முடித்து வைப்பது அவன் செயல் "</p>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">Contact Us</h5>
                <ul>
                    <li>
                    <i className="fas fa-om" /> 
                    <a href="tel:+91 95007 71772">+91 95007 71772</a> 
                    </li>
                    <li>
                    <i className="fas fa-om" />
                    <a href="tel:+91 94430 39348">+91 94430 39348</a> 
                    </li>
                    <li>
                    <i className="fas fa-om" /> 
                    <a href="tel:+91 89030 50820">+91 89030 50820</a>
                    </li>
                    <li>
                    <i className="fas fa-om" /> 
                    <a href='mailto:arunagirithirukovil@gmail.com'>arunagirithirukovil@gmail.com</a>
                    </li>
                </ul>
                </div>
                <div className="col-xl-3 col-lg-2 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">Address</h5>
                <ul>
                    <li>
                    <a href='https://goo.gl/maps/BhxXwvjA8RT35esRA'>Arunagirinathar  Thirukovil,<br></br>Arunagirinathar ManiMandapam ,<br></br>Chengam Road Junction, <br></br>Girivala Paadhai,<br></br> Tiruvannamalai -606603.</a>
                    </li>
                </ul>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-4 col-sm-12 footer-widget">
                <h5 className="widget-title">Quick Links</h5>
                <ul>
                    <li>
                    <i className="fas fa-om" /> 
                    <a href="Home">Home</a> 
                    </li>
                    <li>
                    <i className="fas fa-om" />
                    <a href="About-us">About Us</a> 
                    </li>
                    <li>
                    <i className="fas fa-om" /> 
                    <a href="Blogs">Blogs</a>
                    </li>
                    <li>
                    <i className="fas fa-om" /> 
                    <a href="Gallery">Gallery</a>
                    </li>
                    <li>
                    <i className="fas fa-om" /> 
                    <a href="Construction-Work">Construction Work</a>
                    </li>
                    <li>
                    <i className="fas fa-om" />
                    <a href="Videos">Videos</a>
                    </li>
                </ul>
                </div>
            </div>
            </div>
        </div>
        <div className="sigma_footer-bottom">
            <div className="container-fluid">
            <div className="sigma_footer-copyright">
                <p> Copyright © Arunagirinathar - <a href="www.facebook.com" className="custom-primary">2022</a> </p>
            </div>
            <ul className="sigma_sm square">
                <li>
                <a href="www.facebook.com">
                    <i className="fab fa-facebook-f" />
                </a>
                </li>
                <li>
                <a href="www.facebook.com">
                    <i className="fab fa-linkedin-in" />
                </a>
                </li>
                <li>
                <a href="www.facebook.com">
                    <i className="fab fa-twitter" />
                </a>
                </li>
                <li>
                <a href="https://www.youtube.com/@arunagirinatharthirukovil">
                    <i className="fab fa-youtube" />
                </a>
                </li>
            </ul>
            </div>
        </div>
    </footer>
  )
}

export default Footer