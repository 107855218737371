import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import ReactPlayer from 'react-player'

function Gallery() {

  const handleDragStart = (e) => e.preventDefault();
      
  const [banner, setbanner] = useState([]);
  useEffect(() => {
      getbanner();
  }, []);

  const getbanner = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/banner/`).then(function(response) {
          setbanner(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
  }

  let banner_data_to_html = banner.map((banner,key)=>
          <img key={key} src={`.${banner.image_path.substring(1,1000)}`} onDragStart={handleDragStart} role="presentation" alt=''/>,
      );

  const [videos, setvideos] = useState([]);
  useEffect(() => {
      getvideos();
  }, []);

  const getvideos = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/videos/`).then(function(response) {
          setvideos(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
  }

  let  data_to_html = videos.map((videos,key)=>
            <div key={key} className="col-md-6">
            <article className="sigma_post">
              <div className="sigma_post-thumb">
              <ReactPlayer url={`${videos.video_url}`} />
              </div>
            </article>
          </div>
  );

  return (
    <div>
      <AliceCarousel mouseTracking items={banner_data_to_html} 
            disableDotsControls={true} 
            disableButtonsControls={true} 
            autoPlay={true} 
            infinite={true}
            animationDuration={1000}
            autoPlayInterval={2000}
      />
      <div className="container">
        <div className="sigma_subheader-inner">
        <div className="sigma_subheader-text">
            <h1>Videos</h1>
        </div>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
            <li className="breadcrumb-item"><a className="btn-link" href="Home">Home</a></li>
            <li className="breadcrumb-item active" aria-current="page">Videos</li>
            </ol>
        </nav>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row">

            <div className="col-lg-5">
              <div className="row">
              <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                  <p className='custom_2'>"பேற்றைத் தவம் சற்றும் இல்லாத என்னை, ப்ரபஞ்சம் என்னும்
                    <br></br>சேற்றைக் கழிய வழி விட்டவா, செஞ் சடா அடவி மேல்
                    <br></br>ஆற்றைப் பணியை இதழியை தும்பையை அம்புலியின்
                    <br></br>கீற்றைப் புனைந்த பெருமான், குமாரன் கிருபாகரனே!"
                    <br></br><span className='custom_1'>-கந்தர் அலங்காரம்</span></p>
              </div>
              </div>
              </div>
            </div>

            <div className="col-lg-2">
            <img src="assets/img/e1.jpg" alt="author" />
            </div>

            <div className="col-lg-5">
              <div className="row">
              <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                  <p className='custom_2'>"சேல் பட்டு அழிந்தது செந்தூர் வயற்பொழில் தேங்கடம்பின்
                    <br></br>மால் பட்டு அழிந்தது பூங்கொடியார் மனம் மாமயிலோன்
                    <br></br>வேல் பட்டு அழிந்தது வேலையும் சூரனும் வெற்பும் அவன்
                    <br></br>கால் பட்டு அழிந்தது இங்கு என் தலை மேல் அயன் கையெழுத்தே"
                     <br></br><span className='custom_1'>-கந்தர் அலங்காரம் </span></p>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {data_to_html}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery