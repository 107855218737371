import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import { Image } from 'react-bootstrap';
import {Link} from 'react-router-dom'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function Home() {

    const handleDragStart = (e) => e.preventDefault();

    const [blog, setblog] = useState([]);
    useEffect(() => {
        getblog();
    }, []);
  
    const getblog = async()=>{
        try{
          await axios.get(`${process.env.REACT_APP_API_BASE }/blog/`).then(function(response) {
            setblog(response.data);
        });
        }
        catch(err){
          console.error(err);
        }
    }

  let blog_data_to_html = blog.slice(0,3).map((blog,key) =>    
             <div key={key} className="col-lg-4 col-md-6">
                <article className="sigma_post">
                    <div className="sigma_post-thumb">
                    <Link to={`/${blog.id}`} >
                        <Image src={`.${blog.image_path.substring(1,1000)}`} alt="Awesome_Image" />
                    </Link>
                    </div>
                    <div className="sigma_post-body">
                    <div className="sigma_post-meta">
                        <div className="me-3">
                        <i className="fas fa-om" />
                        <p className="sigma_post-category">{blog.topic}</p>
                        </div>
                        <p className="sigma_post-date"> <i className="far fa-calendar" />{blog.created_at}</p>
                    </div>
                    <h5>{blog.tittle}</h5>
                    <div className="sigma_post-single-author">
                        <img src="assets/img/people/1.jpg" alt="author" />
                        <div className="sigma_post-single-author-content">
                        By <p>{blog.created_by}</p>
                        </div>
                    </div>
                    </div>
                </article>
            </div>);

    const [books, setbooks] = useState([]);
    useEffect(() => {
        getbooks();
    }, []);
  
    const getbooks = async()=>{
        try{
          await axios.get(`${process.env.REACT_APP_API_BASE }/books/`).then(function(response) {
            setbooks(response.data);
        });
        }
        catch(err){
          console.error(err);
        }
    }

    let books_data_to_html = books.slice(0, 12).map((books,key)=>
                <div key={key} className="col-lg-4 col-md-6">
                <article className="sigma_post">
                    <div className="sigma_post-thumb">
                    <a href={`.${books.img_1.substring(1,1000)}`} target="_blank" rel="noreferrer">
                        <img src={`.${books.img_2.substring(1,1000)}`}alt="post" />
                    </a>
                    </div>
                    <div className="sigma_post-body">
                    <h5> <p>{books.tittle}</p> </h5>
                    <a href={`.${books.img_1.substring(1,1000)}`} download className="sigma_btn-custom light">Download Book<i className="far fa-arrow-right" /> </a>
                    </div>
                </article>
                </div>
    );

    const [banner, setbanner] = useState([]);
    useEffect(() => {
        getbanner();
    }, []);
  
    const getbanner = async()=>{
        try{
          await axios.get(`${process.env.REACT_APP_API_BASE }/banner/`).then(function(response) {
            setbanner(response.data);
        });
        }
        catch(err){
          console.error(err);
        }
    }

    let banner_data_to_html = banner.map((banner,key)=>
            <img key={key} src={`.${banner.image_path.substring(1,1000)}`} onDragStart={handleDragStart} role="presentation" alt=''/>,
        );

  return (
    <div>

        <AliceCarousel mouseTracking items={banner_data_to_html} 
            disableDotsControls={true} 
            disableButtonsControls={true} 
            autoPlay={true} 
            infinite={true}
            animationDuration={1000}
            autoPlayInterval={2000}
        />


        <section className="section">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-12 mb-lg-30">
                <div className="img-group">
                    <div className="img-group-inner">
                    <img src="assets/img/Page 1.jpg" alt="about" />
                    <span />
                    <span />
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>


        <div className="section section-padding">
            <div className="text-center">
                <p className="subtitle" style={{color:'red',fontFamily:'flaticon',fontSize:'3.5rem'}}>Arulalar Arunagirinathar Thirukovil</p>
            </div>
            <div className="container">
                <div className="row" style={{paddingTop:'2%',backgroundColor:'aliceblue',outline:'solid'}}>
                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h3>
                                <a href="Home">Dr.M.Sinrasu</a>
                            </h3>
                            <h5>
                                <a href="Home" style={{color:'#285430'}}>Chairman</a>
                            </h5>
                            <h5>
                                <a href="Home">9443039348</a>
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h3>
                                <a href="Home">P.Amaresan <span style={{fontSize:'50%'}}>B.com</span></a>
                            </h3>
                            <h5>
                                <a href="Home" style={{color:'#285430'}}>Secretary</a>
                            </h5>
                            <h5>
                                <a href="Home">9597991771</a>
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h3>
                                <a href="Home">V.Dhanusu <span style={{fontSize:'50%'}}>M.Sc</span></a>
                            </h3>
                            <h5>
                                <a href="Home" style={{color:'#285430'}}>Treasurer</a>
                            </h5>
                            <h5>
                                <a href="Home">9443492480</a>
                            </h5>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       

        <div className="container">
                <div className="section-title text-center">
                    <p className="subtitle">Board of Trustees</p>
                </div>
                <div className="row" style={{paddingTop:'2%',backgroundColor:'aliceblue',outline:'solid'}}>

                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h5>
                                <a href="Home">SR.Muthukrishnan <span style={{fontSize:'50%'}}>B.E.,M.B.A.,L.L.B</span></a>
                            </h5>
                            <h6>
                                <a href="Home" style={{color:'#285430'}}>94431 14548</a>
                            </h6>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h5>
                                <a href="Home">Sethu Murugabopathy <span style={{fontSize:'50%'}}>B.A.,B.L.,</span></a>
                            </h5>
                            <h6>
                                <a href="Home" style={{color:'#285430'}}>96291 84726</a>
                            </h6>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h5>
                                <a href="Home">P.Palaniraj <span style={{fontSize:'50%'}}>B.A.,B.L.,</span></a>
                            </h5>
                            <h6>
                                <a href="Home" style={{color:'#285430'}}>94435 41411</a>
                            </h6>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h5>
                                <a href="Home">P.Ramachandra Upathyaya</a>
                            </h5>
                            <h6>
                                <a href="Home" style={{color:'#285430'}}>94438 15523</a>
                            </h6>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h5>
                                <a href="Home">N.Devaraj</a>
                            </h5>
                            <h6>
                                <a href="Home" style={{color:'#285430'}}>94426 22911</a>
                            </h6>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h5>
                                <a href="Home">P.Ramasamy <span style={{fontSize:'50%'}}>B.A.,B.L.,</span></a>
                            </h5>
                            <h6>
                                <a href="Home" style={{color:'#285430'}}>99427 04144</a>
                            </h6>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h5>
                                <a href="Home">TVS. Rajaram <span style={{fontSize:'50%'}}></span></a>
                            </h5>
                            <h6>
                                <a href="Home" style={{color:'#285430'}}>94431 63285</a>
                            </h6>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">

                        </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="sigma_volunteers volunteers-4">
                        <div className="sigma_volunteers-body">
                            <div className="sigma_volunteers-info">
                            <h5>
                                <a href="Home">TTK.Murugan <span style={{fontSize:'50%'}}></span></a>
                            </h5>
                            <h6>
                                <a href="Home" style={{color:'#285430'}}>95007 71772</a>
                            </h6>
                            </div>
                        </div>
                        </div>
                    </div>

                </div>
        </div>

        <div className="section section-padding">
            <div className="container">
                    <div className="row" style={{paddingTop:'2%',backgroundColor:'aliceblue',outline:'solid'}}>
                        <div className="col-lg-12 col-md-6">
                            <div className="sigma_volunteers volunteers-4">
                            <div className="sigma_volunteers-body">
                                <div className="sigma_volunteers-info">
                                <h5>
                                    <a href="Home">R.Manikumar<span style={{fontSize:'50%'}}></span></a>
                                </h5>
                                <h6>
                                    <a href="Home">Manager</a>
                                </h6>
                                <h6>
                                    <a href="Home" style={{color:'#285430'}}>8903050820</a>
                                </h6>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>

        <section className="section">
            <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 mb-lg-30">
                <div className="img-group">
                    <div className="img-group-inner">
                    <img src="assets/img/About-14.jpeg" alt="about" />
                    <span />
                    <span />
                    </div>
                </div>
                </div>
                <div className="col-lg-6">
                <div className="me-lg-30">
                    <div className="section-title mb-0 text-start">
                    <p className="subtitle">About Us</p>
                    </div>
                    <p className="blockquote bg-transparent" style={{fontSize:'x-large'}}>கெடுவாய் மனனே, கதி கேள், கரவாது
                        <br></br>இடுவாய், வடிவேல் இறைதாள் நினைவாய்
                        <br></br>சுடுவாய் நெடு வேதனை தூள்படவே
                        <br></br>விடுவாய் விடுவாய் வினை யாவையுமே.
                        <br></br>- கந்தர் அனுபூதி </p>
                    <a href="About-Us" className="sigma_btn-custom light">Learn More <i className="far fa-arrow-right" /> </a>
                </div>
                </div>
            </div>
            </div>
        </section>
        
        <div className="section section-padding pattern-squares dark-bg-2">
            <div className="container">
            <div className="section-title text-start">
                <p className="subtitle">service</p>
                <h4 className="title" style={{color:'yellow',right:'50px'}}>How We Can Help</h4>
            </div>
            <div className="row">
                <div className="col-lg-4 col-md-6">
                <a href="About-Us" className="sigma_service style-1 primary-bg">
                    <div className="sigma_service-thumb">
                    <i className="text-white flaticon-temple" />
                    </div>
                    <div className="sigma_service-body">
                    <h5 className="text-white">About Temple</h5>
                    <p className="text-white">Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                    </div>
                    <span className="btn-link text-white">Learn More <i className="text-white far fa-arrow-right" /> </span>
                </a>
                </div>
                <div className="col-lg-4 col-md-6 mt-negative-sm">
                <a href="Books" className="sigma_service style-1 secondary-bg">
                    <div className="sigma_service-thumb">
                    <i className="flaticon-hindu-1" style={{color:'white'}}/>
                    </div>
                    <div className="sigma_service-body">
                    <h5 className="text-white">Books</h5>
                    <p className="text-white">Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                    </div>
                    <span className="text-white btn-link">Learn More <i className="text-white far fa-arrow-right" /> </span>
                </a>
                </div>
                <div className="col-lg-4 col-md-6 mt-negative-sm">
                <a href="Gallery" className="sigma_service style-1">
                    <div className="sigma_service-thumb">
                    <i className="flaticon-pooja" style={{color:'white'}}/>
                    </div>
                    <div className="sigma_service-body">
                    <h5 style={{color:'white'}}>Gallery</h5>
                    <p style={{color:'white'}}>Temple is place where hindu worship  consectetur adipisicing elit, sed do </p>
                    </div>
                    <span className="btn-link" style={{color:'white'}}>Learn More <i className="far fa-arrow-right" style={{color:'white'}}/> </span>
                </a>
                </div>
            </div>
            </div>
        </div>
{/*         <section className="section pt-0">
            <div className="container testimonial-section bg-contain bg-norepeat bg-center" style={{backgroundImage: 'url(assets/img/textures/map-2.png)'}}>
            <div className="section-title text-center">
                <p className="subtitle">Testimonials</p>
                <h4 className="title">What Our Congregation Say</h4>
            </div>
            <div className="sigma_testimonial style-2">
                <div className="sigma_testimonial-slider">
                <div className="sigma_testimonial-inner">
                    <div className="sigma_testimonial-thumb">
                    <img src="assets/img/testimonials/1.jpg" alt="testimonial" />
                    </div>
                    <div>
                    <div className="sigma_testimonial-body">
                        <div className="sigma_rating-wrapper">
                        <div className="sigma_rating">
                            <i className="fas fa-star active" />
                            <i className="fas fa-star active" />
                            <i className="fas fa-star active" />
                            <i className="fas fa-star active" />
                            <i className="far fa-star" />
                        </div>
                        </div>
                        <p>If money help a man to do well to others, it is of some value; but if not, it is simply a mass of evil, and the sooner it is got rid of, the better.</p>
                    </div>
                    <div className="sigma_testimonial-footer">
                        <div className="sigma_testimonial-author">
                        <cite>Mukesh Singh</cite>
                        <span>Pandit</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="sigma_testimonial-inner">
                    <div className="sigma_testimonial-thumb">
                    <img src="assets/img/testimonials/2.jpg" alt="testimonial" />
                    </div>
                    <div>
                    <div className="sigma_testimonial-body">
                        <div className="sigma_rating-wrapper">
                        <div className="sigma_rating">
                            <i className="fas fa-star active" />
                            <i className="fas fa-star active" />
                            <i className="fas fa-star active" />
                            <i className="fas fa-star active" />
                            <i className="far fa-star" />
                        </div>
                        </div>
                        <p>If money help a man to do well to others, it is of some value; but if not, it is simply a mass of evil, and the sooner it is got rid of, the better.</p>
                    </div>
                    <div className="sigma_testimonial-footer">
                        <div className="sigma_testimonial-author">
                        <cite>Das Gupta</cite>
                        <span>Executive</span>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="sigma_testimonial-inner">
                    <div className="sigma_testimonial-thumb">
                    <img src="assets/img/testimonials/3.jpg" alt="testimonial" />
                    </div>
                    <div>
                    <div className="sigma_testimonial-body">
                        <div className="sigma_rating-wrapper">
                        <div className="sigma_rating">
                            <i className="fas fa-star active" />
                            <i className="fas fa-star active" />
                            <i className="fas fa-star active" />
                            <i className="fas fa-star active" />
                            <i className="far fa-star" />
                        </div>
                        </div>
                        <p>If money help a man to do well to others, it is of some value; but if not, it is simply a mass of evil, and the sooner it is got rid of, the better.</p>
                    </div>
                    <div className="sigma_testimonial-footer">
                        <div className="sigma_testimonial-author">
                        <cite>Mukesh Singh</cite>
                        <span>Pandit</span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="text-center mt-4">
                <div className="sigma_arrows style-2">
                <i className="far fa-chevron-left slick-arrow slider-prev" />
                <i className="far fa-chevron-right slick-arrow slider-next" />
                </div>
            </div>
            </div>
        </section> */}
        <div className="section section-padding">
            <div className="container">
            <div className="section-title text-center">
                <p className="subtitle">Latest Blogs</p>
            </div>
            <div className="row">
                {blog_data_to_html}
            </div>
            </div>
        </div>
        <div className="section section-padding">
            <div className="container">
            <div className="section-title text-center">
                <p className="subtitle">Latest Books</p>
            </div>
            <div className="row">
                {books_data_to_html}
            </div>
            </div>
        </div>
        <div className="sigma_top style-5">
            <i className="far fa-angle-double-up" />
        </div>
    </div>
  )
}

export default Home