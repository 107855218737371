import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function Books() {

  const [books, setbooks] = useState([]);
  useEffect(() => {
      getbooks();
  }, []);

  const getbooks = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/books/`).then(function(response) {
          setbooks(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
  }

  let  data_to_html = books.map((books,key)=>
        <div className="col-md-4">
          <article className="sigma_post">
              <div className="sigma_post-thumb">
              <a href={`.${books.img_1.substring(1,1000)}`} target="_blank" rel="noreferrer">
                  <img src={`.${books.img_2.substring(1,1000)}`}alt="post" />
              </a>
              </div>
              <div className="sigma_post-body">
              <h5> <p>{books.tittle}</p> </h5>
              <a href={`.${books.img_1.substring(1,1000)}`} download className="sigma_btn-custom light">Download Book<i className="far fa-arrow-right" /> </a>
              </div>
          </article>
        </div>
  );


  const handleDragStart = (e) => e.preventDefault();
      
  const [banner, setbanner] = useState([]);
  useEffect(() => {
      getbanner();
  }, []);

  const getbanner = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/banner/`).then(function(response) {
          setbanner(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
  }

  let banner_data_to_html = banner.map((banner,key)=>
          <img key={key} src={`.${banner.image_path.substring(1,1000)}`} onDragStart={handleDragStart} role="presentation" alt=''/>,
      );


  return (
    <div>
        <AliceCarousel mouseTracking items={banner_data_to_html} 
            disableDotsControls={true} 
            disableButtonsControls={true} 
            autoPlay={true} 
            infinite={true}
            animationDuration={1000}
            autoPlayInterval={2000}
        />
        <div className="container">
            <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
                <h1>Books</h1>
            </div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a className="btn-link" href="Home">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Books</li>
                </ol>
            </nav>
            </div>
        </div>
        <div>
          <div className="section">
          <div className="container">
            <div className="row">

              <div className="col-lg-5">
                <div className="row">
                <div className="sigma_subheader-inner">
                <div className="sigma_subheader-text">
                    <p className='custom_2'>"சேந்தனைக் கந்தனைச் செங்கோட்டு வெற்பனைச் செஞ்சுடர்வேல்
                      <br></br>வேந்தனைச் செந்தமிழ் நூல் விரித்தோனை விளங்கு வள்ளி
                      <br></br>காந்தனைக் கந்தக் கடம்பனைக் கார்மயில் வாகனனைச்
                      <br></br>சாந்துணைப் போதும் மறவாதவர்க்கு ஒரு தாழ்வில்லையே."
                      <br></br><span className='custom_1'>-கந்தர் அலங்காரம்</span></p>
                </div>
                </div>
                </div>
              </div>

              <div className="col-lg-2">
              <img src="assets/img/e1.jpg" alt="author" />
              </div>

              <div className="col-lg-5">
                <div className="row">
                <div className="sigma_subheader-inner">
                <div className="sigma_subheader-text">
                    <p className='custom_2'>"உருவாய் அருவாய், உளதாய் இலதாய்
                        <br></br>மருவாய் மலராய், மணியாய் ஒளியாய்க்
                        <br></br>கருவாய் உயிராய்க், கதியாய் விதியாய்க்
                        <br></br>குருவாய் வருவாய், அருள்வாய் குகனே."
                      <br></br><span className='custom_1'>-கந்தர் அலங்காரம் </span></p>
                </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
          <div className="section">
            <div className="container">
              <div className="row">
                <div className="col-lg-8" style={{width: '100%'}}>
                  <div className="row">
                      {data_to_html}
                  </div>
                </div>
              </div>
            </div>
          </div>
    </div>
  )
}

export default Books