import React from 'react'
import axios from 'axios'
import { useEffect, useState } from "react";
import ModalImage from "react-modal-image";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

function Gallery() {

  const handleDragStart = (e) => e.preventDefault();
      
  const [banner, setbanner] = useState([]);
  useEffect(() => {
      getbanner();
  }, []);

  const getbanner = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/banner/`).then(function(response) {
          setbanner(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
  }

  let banner_data_to_html = banner.map((banner,key)=>
          <img key={key} src={`.${banner.image_path.substring(1,1000)}`} onDragStart={handleDragStart} role="presentation" alt=''/>,
      );


  const [gallery, setgallery] = useState([]);
  useEffect(() => {
      getgallery();
  }, []);

  const getgallery = async()=>{
      try{
        await axios.get(`${process.env.REACT_APP_API_BASE }/gallery/`).then(function(response) {
          setgallery(response.data);
      });
      }
      catch(err){
        console.error(err);
      }
  }

  let data_to_html = gallery.map((gallery,key)=>
        <div key={key} className="col-md-4">
        <article className="sigma_post">
          <div className="sigma_post-thumb">
            <ModalImage
              small={`.${gallery.image_path.substring(1,1000)}`}
              large={`.${gallery.image_path.substring(1,1000)}`}
            />
          </div>
          <div className="sigma_post-body">
            <h5>{gallery.tittle}</h5>
          </div>
        </article>
      </div>
  );

  return (
    <div>
      <AliceCarousel mouseTracking items={banner_data_to_html} 
            disableDotsControls={true} 
            disableButtonsControls={true} 
            autoPlay={true} 
            infinite={true}
            animationDuration={1000}
            autoPlayInterval={2000}
      />
      <div className="container">
            <div className="sigma_subheader-inner">
            <div className="sigma_subheader-text">
                <h1>Gallery</h1>
            </div>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><a className="btn-link" href="Home">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">Gallery</li>
                </ol>
            </nav>
            </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">

            <div className="col-lg-5">
              <div className="row">
              <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                  <p className='custom_2'>"படிக்கின்றிலை, பழநித் திரு நாமம் படிப்பவர் தாள்
                    <br></br>முடிக்கின்றிலை, முருகா என்கிலை, முசியாமல் இட்டு
                    <br></br>மிடிக்கின்றிலை, பரமானந்தம் மேற் கொள விம்மி விம்மி
                    <br></br>நடிக்கின்றிலை, நெஞ்சமே தஞ்சம் ஏது நமக்கு இனியே?"
                    <br></br><span className='custom_1'>-கந்தர் அலங்காரம்</span></p>
              </div>
              </div>
              </div>
            </div>

            <div className="col-lg-2">
            <img src="assets/img/e1.jpg" alt="author" />
            </div>

            <div className="col-lg-5">
              <div className="row">
              <div className="sigma_subheader-inner">
              <div className="sigma_subheader-text">
                  <p className='custom_2'>"விழிக்குத் துணை திரு மென்மலர்ப் பாதங்கள் - மெய்ம்மை குன்றா
                      <br></br>மொழிக்குத் துணை முருகா எனும் நாமங்கள் - முன்பு செய்த
                      <br></br>பழிக்குத் துணை அவன் பன்னிரு தோளும் பயந்த தனி
                      <br></br>வழிக்குத் துணை வடிவேலும் செங்கோடன் மயூரமுமே!"
                     <br></br><span className='custom_1'>-கந்தர் அலங்காரம் </span></p>
              </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {data_to_html}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Gallery